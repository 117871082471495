import { api as axiosApi } from 'src/boot/axios.js'
import { storeToRefs } from 'pinia'

import { usePlatformSettingStore } from 'src/stores/PlatformSetting'
import { useAuthStore } from 'src/stores/AuthStore'

import api from 'src/api'

const getSiteUrl = () => {
  let siteUrl = window.location.host

  if (siteUrl === 'localhost:9000' || siteUrl === '192.168.0.195:9000' || siteUrl === 'localhost:9200' || siteUrl === '192.168.0.195:9200' || siteUrl === 'localhost:3000') {
    siteUrl = 'imlabs.ead.imlabs.com.br'
  }

  return siteUrl
}

const getShortVideos = async () => {
  const authStore = useAuthStore()
  let shortVideos = await api.shortVideos.get()

  shortVideos.forEach((shortVideo) => {
    const token = authStore.getToken
    const baseUrl = axiosApi.defaults.baseURL.replace('/api/v1', '')
    const resource = encodeURI(shortVideo.resource)

    shortVideo.resource = `${baseUrl}/video/proxy?resource=${resource}&token=${token}`
  })

  return shortVideos
}

const getPlatformSetting = async (platformId) => {
  const platformSettingStore = usePlatformSettingStore()
  const { platformSetting } = storeToRefs(platformSettingStore)

  if (platformSetting.value) {
    return platformSetting.value
  }

  const data = await api.platforms.settings.get(platformId)
  platformSetting.value = data

  return platformSetting.value
}

const startShortVideo = async (shortVideoId) => {
  await api.shortVideos.start.post(shortVideoId)
}

const endShortVideo = async (shortVideoId) => {
  await api.shortVideos.end.post(shortVideoId)
}

const generateEasterEgg = () => {
  /* eslint-disable no-console */
  console.log(
    ' ________      ___ __ __      __           ________        _______       ______\n/_______/\\    /__//_//_/\\    /_/\\         /_______/\\     /_______/\\     /_____/\\\n\\__.::._\\/    \\::\\| \\| \\ \\   \\:\\ \\        \\::: _  \\ \\    \\::: _  \\ \\    \\::::_\\/ \n   \\::\\ \\      \\:.      \\ \\   \\:\\ \\        \\::(_)  \\ \\    \\::(_)  \\/_    \\:\\___/\\\n   _\\::\\ \\__    \\:.\\-/\\  \\ \\   \\:\\ \\____    \\:: __  \\ \\    \\::  _  \\ \\    \\_::._\\:\\\n  /__\\::\\__/\\    \\. \\  \\  \\ \\   \\:\\/___/\\    \\:.\\ \\  \\ \\    \\::(_)  \\ \\     /____\\:\\\n  \\________\\/     \\__\\/ \\__\\/    \\_____\\/     \\__\\/\\__\\/     \\_______\\/     \\_____\\/',
  )
  /* eslint-enable no-console */
}

export {
  getSiteUrl,
  getShortVideos,
  getPlatformSetting,
  startShortVideo,
  endShortVideo,
  generateEasterEgg,
}

import { route } from 'quasar/wrappers'
import formbricks from '@formbricks/js/app'
import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
  createWebHashHistory,
} from 'vue-router'

import { hideIMBotscript } from 'src/services/InjectScripts'

import routes from 'src/router/routes'
import { useAuthStore } from 'src/stores/AuthStore'

export default route(() => {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
      ? createWebHistory
      : createWebHashHistory

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
    history: createHistory(process.env.VUE_ROUTER_BASE),
  })

  Router.beforeEach((to, from, next) => {
    if (to.path !== '/') {
      hideIMBotscript()
    }

    if (window.formbricks) {

      formbricks.registerRouteChange();

      const coursePathRegex = /^\/courses\/([^\/]+)\/?.*$/;
      const match = to.path.match(coursePathRegex);
      if (match) {
        formbricks.setAttribute("courseId", match[1]);
      }

    }

    const authStore = useAuthStore()

    authStore.verifyUserAuthenticationInPrivateRoutes(to, from, next)
  })

  Router.afterEach((to, from) => {

    if (!to.meta?.requiresAuth) {
      const formbricksModal = document.querySelectorAll('#formbricks-modal-container');

      if (formbricksModal.length > 0) {

        formbricksModal.forEach((modal) => {

          modal.remove();

        });
      }
      
      return;
    }

  })

  return Router
})
